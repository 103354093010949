import React from "react";
import FlexContainer from "../../../Common/Components/FlexContainer";
import about from "../../../assets/about-fault-detection.webp";
import challenge from "../../../assets/works-fault-detection.webp";
import fdWorks from "../../../assets/fd-works.webp";
import target from "../../../assets/target-fault-detection.webp";
import ai from "../../../assets/ai.webp";
import Banner from "../../../Common/Components/Banner";
import test from "../../../assets/test.webp";
import db from "../../../assets/db.webp";
import loadingSign from "../../../assets/loading-sign.webp";
import ContactUs from "../../../Common/ContactUs/ContactUs";
import TextCapsules from "../../../Common/Components/TextCapsules";
import "./FaultDetection.css";

const FaultDetection = () => {
  return (
    <div className="container-mw">
      <div className="container about">
        <FlexContainer
          src={about}
          tag={"About Fault Detection"}
          title={"Revolutionizing PCB Inspection with AI-Driven "}
          glow={"Fault Detection"}
          desc={
            "The inspection of Surface Mount Technology (SMT) and Printed Circuit Boards (PCBs) is critical for ensuring the quality and reliability of electronic components. Our AI-driven solution addresses the common challenges of identifying and localizing faults, such as missing screws or components in the PCB, irregularities in the structure of the chassis, and defects in the application thermal paste. By leveraging advanced image processing and deep learning techniques, we provide a reliable and efficient method for detecting these faults early in the production process."
          }
        />
      </div>
      <div className="container challenge">
        <Banner
          tag={"Challenge"}
          title={"Challenges in "}
          glow={"SMT/PCB Quality Surveillance"}
          src={challenge}
          desc={
            "Quality surveillance in Surface Mount Technology (SMT) and PCB manufacturing is crucial for preventing defects that could compromise the performance and safety of electronic products. However, traditional inspection methods often struggle with accurately detecting and localizing faults in PCB involve human error, and require time. These issues can lead to significant delays and costly rework, impacting both productivity and profitability."
          }
        />
      </div>
      <div className="container works">
        <TextCapsules text={"How it works?"} />
        <div className="heading1">
          {"Precision"}
          <span className="text-gradient">{" Fault Detection "}</span>
          {"in Four Simple Steps"}
        </div>
        <div className="works-imgs">
          <img src={fdWorks} alt="" />
          <img src={fdWorks} alt="" />
          <img src={fdWorks} alt="" />
        </div>
        <div className="flex-container2-grid features-grid">
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={db} alt="" />
            </div>
            <div>
              <div className="heading2">{"Data Collection"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "We begin by capturing images that illustrate various faults in Surface Mount / PCB assemblies, creating a comprehensive dataset for analysis."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={loadingSign} alt="" />
            </div>
            <div>
              <div className="heading2">
                {"Image Pre-processing and Annotation"}
              </div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  " Each image is carefully pre-processed and annotated to highlight different types of faults, ensuring that the model can learn to identify them accurately."
                }
              </div>
            </div>
          </div>{" "}
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={test} alt="" />
            </div>
            <div>
              <div className="heading2">{"Model Training and Testing"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  " Multiple models, including Template Matching, YOLO, and custom CNN architectures are used to train and test efficiency in fault detection."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={ai} alt="" />
            </div>
            <div>
              <div className="heading2">{"Optimal Model Selection"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "The model with the highest accuracy and minimal latency is selected for deployment, ensuring reliable and timely detection of faults in real-world scenarios."
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container target set-order">
        <FlexContainer
          tag={"Target Industries"}
          title={"Target Users for"}
          glow={" Fault Detection"}
          desc={
            <>
              <p>
                Our solution is designed to meet the needs of industries where
                precision and reliability are non-negotiable:
              </p>
              <br />
              <p>
                <strong>Electronics Manufacturing:</strong> Ensuring the quality
                of PCBs used in consumer electronics, industrial equipment, and
                automotive systems.
              </p>
              <br />

              <p>
                <strong>Aerospace and Defense:</strong> Supporting the high
                standards required for electronic components in aerospace and
                defense applications.
              </p>
              <br />

              <p>
                <strong>Medical Devices:</strong> Providing fault-free PCBs
                critical for the safe operation of medical devices and
                equipment.
              </p>
              <br />

              <p>
                <strong>Automotive:</strong> Enhancing the reliability of
                automotive electronics, from control systems to infotainment.
              </p>
            </>
          }
          src={target}
        />
      </div>
      <div>
        <ContactUs />
      </div>
    </div>
  );
};

export default FaultDetection;
