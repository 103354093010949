import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/f-logo.svg";


const Footer = () => {
  return (
    <div className="footer container-mt">
      <div className="footer-main container-pd container-mw">
      <img src={logo} alt="logo" className="logo" />
        <div className="page-links">
          <div className="paragraph1">
            <Link
              to="/"
              onClick={(e) => {
                window.scrollTo(0, 0);
                // e.preventDefault();
                // window.location.href = "/";
              }}
            >
              Home
            </Link>
          </div>
          <div className="paragraph1">
            <Link
              to="/aboutus"
              onClick={(e) => {
                window.scrollTo(0, 0);
                // e.preventDefault();
                // window.location.href = "/aboutus";
              }}
            >
              About us
            </Link>
          </div>
          <div className="paragraph1">
            <Link
              to="/melodi"
              onClick={(e) => {
                window.scrollTo(0, 0);
                // e.preventDefault();
                // window.location.href = "/melodi";
              }}
            >
              MeLodi
            </Link>
          </div>
          <div className="paragraph1">
            <Link
              to="/doctalk"
              onClick={(e) => {
                // e.preventDefault();
                window.scrollTo(0, 0);
                // window.location.href = "/doctalk";
              }}
            >
              DocTalk
            </Link>
          </div>
          <div className="paragraph1">
            <Link
              to="/fault-detection"
              onClick={(e) => {
                // e.preventDefault();
                window.scrollTo(0, 0);
                // window.location.href = "/fault-detection";
              }}
            >
              Fault Detection
            </Link>
          </div>
          <div className="paragraph1">
            <Link
              to="/spanfi"
              onClick={(e) => {
                window.scrollTo(0, 0);
                // e.preventDefault();
                // window.location.href = "/spanfi";
              }}
            >
              SpanFi
            </Link>
          </div>
        </div>
        {/* <div className="social-links">
          <div>
            <img src={fbIcon} alt="fbIcon" />
          </div>
          <div>
            <img src={instaIcon} alt="instaIcon" />
          </div>
          <div>
            <img src={twitterIcon} alt="twitterIcon" />
          </div>
          <div>
            <img src={linkedinIcon} alt="linkedinIcon" />
          </div>
          <div>
            <img src={youtubeIcon} alt="youtubeIcon" />
          </div>
        </div> */}
      </div>
      <div className="footer-end">
        <div className="footer-hr"></div>
        <div className="footer-static-page-links">
          <div className="paragraph1">SISDEA 2024 . All right reserved.</div>
          <a
            href="/privacy-policy"
            className="paragraph1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="/terms"
            className="paragraph1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          {/* <div className="paragraph1">Cookies Settings</div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
