import React from "react";
import Banner from "../../../Common/Components/Banner";
import banner from "../../../assets/banner-aiTut.webp";
import aiTutWorks from "../../../assets/aiTut-works.webp";
import quick from "../../../assets/quick.webp";
import skill from "../../../assets/skill-icon.webp";
import info from "../../../assets/info-icon.webp";
import easy from "../../../assets/easy-icon.webp";
import aiTutFeatures from "../../../assets/aiTut-features.webp";
import targetAiTut from "../../../assets/target-aiTut.webp";
import FlexContainer from "../../../Common/Components/FlexContainer";
import FlexContainer2 from "../../../Common/Components/FlexContainer2";
import ContactUs from "../../../Common/ContactUs/ContactUs";
import "./AiTutor.css";

const AiTutor = () => {
  return (
    <div className="ai-tutor container-mw">
      <div className="container">
        <Banner
          tag={"About DocTalk"}
          title={"Transform Your "}
          glow={"Learning Experience"}
          src={banner}
          desc={
            "DocTalk is an advanced tool designed to simplify the way you interact with educational content. Upload a PDF containing school-related material—such as science notes—and get instant, accurate answers to your questions. DocTalk makes studying more efficient by providing clear, immediate responses to help you understand your coursework better."
          }
        />
      </div>
      <div className="container ai-tutor-works">
        <FlexContainer
          src={aiTutWorks}
          tag={"How DocTalk Works"}
          title={"Effortless Information "}
          glow={"Retrieval"}
          desc={
            "With DocTalk, finding information in educational PDFs has never been easier. Simply upload your document, ask your question, and let our advanced AI process the content. DocTalk reads and understands the material, delivering precise answers that fit the context of your queries, making your learning experience seamless and interactive."
          }
        />
      </div>
      <div className="container set-order">
        <FlexContainer2
          tag={"Features"}
          title={"Instant "}
          glow={"Question Answering"}
          src={aiTutFeatures}
          gridItems={[
            {
              src: quick,
              title: "Crisp and Quick",
              desc: "MeLoDi automatically processes and analyzes log data, reducing the need for manual review and saving time. This means you get insights faster and more efficiently.",
            },
            {
              src: skill,
              title: "To the point and in the context",
              desc: "MeLoDi automatically processes and analyzes log data, reducing the need for manual review and saving time. This means you get insights faster and more efficiently.",
            },
            {
              src: info,
              title: "Versatile Assistance",
              desc: "MeLoDi automatically processes and analyzes log data, reducing the need for manual review and saving time. This means you get insights faster and more efficiently.",
            },
            {
              src: easy,
              title: "Easy to Use",
              desc: "MeLoDi doesn’t just react to data; it forecasts future issues based on trends and historical data, helping you address potential problems before they impact your business.",
            },
          ]}
        />
      </div>
      <div className="container">
        <FlexContainer
          tag={"Target Users"}
          title={"Target Users for "}
          glow={"DocTalk"}
          desc={
            <>
              <p>
                <strong>For Students:</strong> No matter your class or subject,
                the DocTalk is your teacher, just a click away to resolve any
                doubts you may have. And for those who consider themselves
                lifelong learners, DocTalk is your lifelong guide, always ready
                to assist you on your educational journey!
              </p>
              <br />
              <p>
                <strong>For Teachers:</strong> To continuously enhance your
                teaching skills and ensure you deliver precise and accurate
                information on every topic or subtopic, look no further than the
                DocTalk!
              </p>
              <br />

              <p>
                <strong>For Professionals:</strong> In today's fast-paced world,
                professionals need to grasp a wide range of topics, and review
                documents, specifications, emails, papers, and publications.
                With DocTalk by their side, they can efficiently process all
                this information like a pro. DocTalk also assists in creating
                notes and summaries, making their work even more manageable!
              </p>
              <br />

              <p>
                <strong>Efficient Learning Tools:</strong> Integrate DocTalk
                into your resources to support both students and educators with
                fast, reliable information retrieval.
              </p>
            </>
          }
          src={targetAiTut}
        />
      </div>
      <div>
        <ContactUs />
      </div>
    </div>
  );
};

export default AiTutor;
