import React from "react";
import "../Home.css";
import rotatingEarth from "../../../assets/rotating-earth.mp4";
import aboutBg from "../../../assets/about-bg.webp";
import TextCapsules from "../../../Common/Components/TextCapsules";

const Hero = () => {
  return (
    <div className="hero" style={{ marginTop: "112px" }}>
      <TextCapsules text={"SISDEA Presents"} />
      <div className="heading1" style={{ maxWidth: "853px" }}>
        Transforming Ideas into{" "}
        <span className="text-gradient">Intelligent Solutions</span>
      </div>
      <div className="paragraph1" style={{ marginTop: 20, maxWidth: "853px" }}>
        Discover cutting-edge AI and IoT Products designed to drive innovation
        and efficiency. Our advanced solutions empower our customers to achieve
        success.
      </div>
      <div className="rotating-earth" style={{ marginTop: 84 }}>
        <video src={rotatingEarth} autoPlay loop muted></video>
      </div>
      <div className="hero-about">
        <div className="about-bg">
          <img src={aboutBg} alt="aboutBg" />
        </div>
        <div className="about-overlay-text desktop">
          <TextCapsules
            text={"About SISDEA"}
            textColor={"white"}
            bgColor={"transparent"}
          />
          <div className="heading1">Innovating with Intelligence</div>
          <div className="paragraph1">
            At SISDEA, we are dedicated to transforming businesses with our
            state-of-the-art AI solutions. Our mission is to empower companies
            across various industries with intelligent products that drive
            innovation, efficiency and growth. From predictive analytics to
            advanced automation, our AI technologies are designed to solve
            real-world challenges and unlock new opportunities.
          </div>
          <TextCapsules
            text={"Know More"}
            textColor={"white"}
            bgColor={"transparent"}
            showArrow={true}
            isClickable={true}
            href="/aboutus"
          />
        </div>
        <div className="about-overlay-text mobile">
          <TextCapsules
            text={"About SISDEA"}
            textColor={"black"}
            bgColor={"transparent"}
          />
          <div className="heading1">Innovating with Intelligence</div>
          <div className="paragraph1">
            At SISDEA, we are dedicated to transforming businesses with our
            state-of-the-art AI solutions. Our mission is to empower companies
            across various industries with intelligent products that drive
            innovation, efficiency and growth. From predictive analytics to
            advanced automation, our AI technologies are designed to solve
            real-world challenges and unlock new opportunities.
          </div>
          <TextCapsules
            text={"Know More"}
            textColor={"white"}
            bgColor={"#2D2D2D"}
            showArrow={true}
            isClickable={true}
            href="/aboutus"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
