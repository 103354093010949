import React from "react";
import Banner from "../../../../Common/Components/Banner";
import banner from "../../../../assets/banner-melodi-works.webp";

const MelodiWorks = () => {
  return (
    <div className="container melodi-works">
      <Banner
        tag={"How MeLoDi Works"}
        title={"SISDEA's GEN AI LLM for "}
        glow={" Log Analysis"}
        src={banner}
        desc={
          "MeLoDi simplifies log analysis by Gen AI LLM using smart algorithms to process large amounts of data quickly. Instead of manually sifting through logs, MeLoDi uses AI to find patterns, spot unusual activity, and predict potential issues. This helps businesses understand their systems better and respond faster to any problems, improving both performance and security."
        }
      />
    </div>
  );
};

export default MelodiWorks;
