import React from "react";
import Banner from "../../../Common/Components/Banner";
import banner from "../../../assets/banner-spanfi.webp";
import banner2 from "../../../assets/banner-spanfi-f.webp";
import banner3 from "../../../assets/banner-spanfi-t.webp";
import SystemTask from "../../../assets/System Task.webp";
import Electronics from "../../../assets/Electronics.webp";
import Automation from "../../../assets/Automation.webp";
import CloudUserGroup from "../../../assets/Cloud User Group.webp";
import Network from "../../../assets/Network.webp";
import db from "../../../assets/db.webp";
import ai from "../../../assets/ai.webp";
import ContactUs from "../../../Common/ContactUs/ContactUs";
import "./Spanfi.css";


const Spanfi = () => {
  return (
    <div className="spanfi container-mw">
      <div className="container about-spanfi">
        <Banner
          tag={"About SpanFi"}
          title={"Effortless management and monitoring of the "}
          glow={"broadband devices"}
          src={banner}
          desc={
            "SpanFi is an advanced cloud-based platform engineered for the provisioning, monitoring, and management of broadband devices. It enables service providers to elevate customer Quality of Experience while simplifying device deployment and network management. Designed for seamless integration with diverse wireless gateways and CPE devices, SpanFi provides a holistic solution that supports both public and residential WiFi networks. Built on a scalable, vendor-agnostic foundation, SpanFi empowers operators to deploy cloud-managed WiFi networks efficiently, with the flexibility to run on public cloud or on-premises infrastructure, ensuring adaptability and reliability across varying deployment environments."
          }
        />
      </div>
      <div className="container spanfi-features">
        <div style={{ margin: "68px 0" }} className="">
          <Banner
            tag={"Features"}
            title={" Experience the Full Spectrum of Features with "}
            glow={"SpanFi"}
            src={banner2}
          />
        </div>
        <div className="flex-container2-grid features-grid">
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={SystemTask} alt="" />
            </div>
            <div>
              <div className="heading2">
                {"Real-Time Performance Monitoring"}
              </div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Continuously tracks broadband service metrics, providing deep visibility into network performance and usage patterns."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={Automation} alt="" />
            </div>
            <div>
              <div className="heading2">{"Seamless AP Configuration"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Streamlines the configuration process for broadband access points, ensuring rapid deployment and scalability."
                }
              </div>
            </div>
          </div>{" "}
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={CloudUserGroup} alt="" />
            </div>
            <div>
              <div className="heading2">{"Web-Based User Access"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Enables secure, on-demand access for users to monitor access point status, manage configurations, and leverage new applications through an intuitive web interface."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={ai} alt="" />
            </div>
            <div>
              <div className="heading2">
                {"Automated Diagnostics and Self-Healing"}
              </div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Performs proactive diagnostics on access points, utilizing AI-driven preventive and corrective actions to optimize Quality of Experience (QoE)."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={db} alt="" />
            </div>
            <div>
              <div className="heading2">{"Data-Driven Insights"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Analyzes extensive network data to generate actionable insights, supporting customer experience enhancement and revenue optimization."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={Network} alt="" />
            </div>
            <div>
              <div className="heading2">{"Instant Network Creation"}</div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Facilitates rapid setup of private and public networks with a streamlined mechanism for adding and managing access points."
                }
              </div>
            </div>
          </div>
          <div className="flex-container2-grid-item">
            <div className="grid-icon">
              <img src={Electronics} alt="" />
            </div>
            <div>
              <div className="heading2">
                {"FOTA (Firmware Over-the-Air) Updates"}
              </div>
              <div className="paragraph1" style={{ marginTop: 10 }}>
                {
                  "Enables batch firmware updates across device fleets, ensuring all access points stay secure and up-to-date with minimal manual intervention."
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Banner
          tag={"The Technologies"}
          title={"Advanced Technology Stack Driving "}
          glow={"SpanFi's Broadband Solution"}
          src={banner3}
        />
        <div
          className="paragraph1 tech-desc"
          style={{
            display: "flex",
            textAlign: "left",
            gap: "35px",
            marginTop: "20px",
          }}
        >
          <div>
            <p>
              <strong>Azure IoT Hub:</strong> Facilitates management of
              large-scale IoT deployments, providing comprehensive device
              provisioning, monitoring, and configuration across billions of
              connected devices.
            </p>

            <br />

            <p>
              <strong>Azure Blob Storage:</strong> Optimized cloud object
              storage for vast amounts of unstructured data, ideal for handling
              device-generated text and binary data.
            </p>

            <br />

            <p>
              <strong>Backend Application:</strong> Built on a microservices
              architecture leveraging Java, Spring Boot, Hibernate, and Node.js
              (Express.js) for enhanced modularity, scalability, and
              reliability.
            </p>

            <br />

            <p>
              <strong>Web Application:</strong> The frontend interface is
              crafted with Angular, TypeScript, Bootstrap, HTML, and CSS,
              providing a responsive, user-friendly experience for device
              monitoring and management.
            </p>

            <br />

            <p>
              <strong>Communication Protocols:</strong> Utilizes HTTP,
              WebSocket, and MQTT protocols to ensure secure, low-latency
              communication between devices and backend systems.
            </p>
          </div>

          <div>
            <p>
              <strong>Apache Kafka:</strong> Enables efficient, real-time
              microservice communication, supporting high-throughput data
              pipelines and event streaming.
            </p>

            <br />

            <p>
              <strong>MySQL Database:</strong> Used for secure storage and
              management of metadata related to device configurations and SpanFi
              server operations.
            </p>

            <br />

            <p>
              <strong>Keycloak:</strong> Open-source Identity and Access
              Management (IAM) solution for secure API access and user
              authorization, ensuring data privacy and compliance.
            </p>

            <br />

            <p>
              <strong>Deployment:</strong> Docker containerization for
              microservices, enhancing portability, simplified scaling, and
              consistent deployment across environments.
            </p>

            <br />

            <p>
              <strong>OpenWRT:</strong> A Linux-based distribution tailored for
              embedded systems and networking, used in SpanFi’s firmware to
              support device-level network configuration and management.
            </p>
          </div>
        </div>
      </div>
      <div>
        <ContactUs />
      </div>
    </div>
  );
};

export default Spanfi;
