import React from "react";
import TextCapsules from "../../../Common/Components/TextCapsules";
import blub from "../../../assets/blub-icon.webp";
import graduationCap from "../../../assets/graduation-cap-icon.webp";
import handshake from "../../../assets/handshake-icon.webp";
import people from "../../../assets/people-icon.webp";
import customer from "../../../assets/customer-icon.webp";
import Sustainability from "../../../assets/Sustainability-icon.webp";

const CoreValues = () => {
  return (
    <div className="core-values container">
      <TextCapsules text={"Our Core Value"} />
      <div className="heading1">
        Guiding Principles Behind{" "}
        <span className="text-gradient">Our Innovation</span>
      </div>
      <div className="core-value-grid">
        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={blub} alt="blub" />
          </div>
          <div className="heading2">Innovation</div>
          <div className="paragraph1">
            We constantly push the boundaries of technology, fostering a culture
            of creativity and forward-thinking to develop cutting-edge AI
            solutions.
          </div>
        </div>

        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={graduationCap} alt="graduationCap" />
          </div>
          <div className="heading2">Excellence</div>
          <div className="paragraph1">
            Our commitment to quality ensures that we deliver top-tier products
            and services, consistently exceeding expectations and setting new
            standards.
          </div>
        </div>
        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={handshake} alt="handshake" />
          </div>
          <div className="heading2">Integrity</div>
          <div className="paragraph1">
            We build trust through honesty, transparency and ethical practices
            in all our interactions and business operations.
          </div>
        </div>
        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={people} alt="people" />
          </div>
          <div className="heading2">Collaboration</div>
          <div className="paragraph1">
            We believe in the power of teamwork, working closely with our
            clients and partners to achieve shared goals and create impactful
            solutions.
          </div>
        </div>
        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={customer} alt="customer" />
          </div>
          <div className="heading2">Customer Focus</div>
          <div className="paragraph1">
            Our clients are at the heart of everything we do. We listen to their
            needs, understand their challenges and tailor our solutions to
            drive their success.
          </div>
        </div>
        <div className="core-value-grid-item">
          <div className="grid-icon">
            <img src={Sustainability} alt="Sustainability" />
          </div>
          <div className="heading2">Sustainability</div>
          <div className="paragraph1">
            We are committed to creating AI solutions that not only advance
            technology but also contribute to a sustainable and responsible
            future.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
