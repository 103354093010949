import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Footer from "./Common/Footer/Footer";
import Navbar from "./Common/Navbar/Navbar";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Home from "./Pages/Home/Home";
import AiTutor from "./Pages/OurProduct/AiTutor/AiTutor";
import FaultDetection from "./Pages/OurProduct/FaultDetection/FaultDetection";
import Melodi from "./Pages/OurProduct/Melodi/Melodi";
import TandC from "./Pages/StaticContent/TandC";
import PrivacyPolicy from "./Pages/StaticContent/PrivacyPolicy";
import Spanfi from "./Pages/OurProduct/Spanfi/Spanfi";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/melodi" element={<Melodi />} />
        <Route path="/doctalk" element={<AiTutor />} />
        <Route path="/fault-detection" element={<FaultDetection />} />
        <Route path="/spanfi" element={<Spanfi />} />
        <Route path="/terms" element={<TandC />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Wildcard route to handle invalid URLs */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
