import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import TextCapsules from "../Components/TextCapsules";
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";

const Navbar = () => {
  const location = useLocation();
  const getInitialRoute = () => {
    switch (location.pathname) {
      case "/home":
        return "home";
      case "/aboutus":
        return "aboutus";
      case "/melodi":
        return "our-product";
      case "/doctalk":
        return "our-product";
      case "/fault-detection":
        return "our-product";
      case "/spanfi":
        return "our-product";
      default:
        return "home";
    }
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [lastClicked, setLastClicked] = useState(getInitialRoute());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleNav = () => {
    const hamburgerMenu = document.querySelector(".hamburger-menu");
    hamburgerMenu.classList.toggle("change");
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="header">
      <div className="navbar navbar-mobile container-mw container-pd">
        <div className="navs">
          <div>
            {" "}
            <Link
              to="/"
              onClick={() => {
                setLastClicked("home");
              }}
            >
              <img src={logo} alt="logo" className="logo" />
            </Link>
          </div>
          <div className="nav-items">
            <div
              className={`nav-item header-text ${
                lastClicked === "home" ? "active" : ""
              }`}
            >
              <Link
                to="/"
                onClick={() => {
                  setLastClicked("home");
                  window.scrollTo(0, 0);
                }}
              >
                Home
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "aboutus" ? "active" : ""
              }`}
            >
              <Link
                to="/aboutus"
                onClick={() => {
                  setLastClicked("aboutus");
                  window.scrollTo(0, 0);
                }}
              >
                About Us
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "our-product" ? "active" : ""
              }`}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
              onClick={toggleDropdown}
            >
              Our Product
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <Link
                    to="/melodi"
                    onClick={() => {
                      setLastClicked("our-product");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="dropdown-item">MeLoDi</div>
                  </Link>
                  <Link
                    to="/doctalk"
                    onClick={() => {
                      setLastClicked("our-product");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="dropdown-item">DocTalk</div>
                  </Link>
                  <Link
                    to="/fault-detection"
                    onClick={() => {
                      setLastClicked("our-product");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="dropdown-item">Fault Detection</div>
                  </Link>
                  <Link
                    to="/spanfi"
                    onClick={() => {
                      setLastClicked("our-product");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="dropdown-item">SpanFi</div>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="hamburger-menu" onClick={toggleNav}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
        <TextCapsules
          text={"Contact us"}
          textColor={"white"}
          bgColor={"#2D2D2D"}
          isClickable={true}
          href="#contact-us"
        />
        {mobileMenuOpen ? (
          <div className="nav-items-mob">
            <div
              className={`nav-item header-text ${
                lastClicked === "home" ? "active" : ""
              }`}
            >
              <Link
                to="/"
                onClick={() => {
                  toggleNav();
                  setLastClicked("home");
                  window.scrollTo(0, 0);
                }}
              >
                Home
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "aboutus" ? "active" : ""
              }`}
            >
              <Link
                to="/aboutus"
                onClick={() => {
                  toggleNav();
                  setLastClicked("aboutus");
                  window.scrollTo(0, 0);

                }}
              >
                About Us
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "Melodi" ? "active" : ""
              }`}
            >
              <Link
                to="/melodi"
                onClick={() => {
                  toggleNav();
                  setLastClicked("Melodi");
                  window.scrollTo(0, 0);

                }}
              >
                Melodi
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "DocTalk" ? "active" : ""
              }`}
            >
              <Link
                to="/doctalk"
                onClick={() => {
                  toggleNav();
                  setLastClicked("DocTalk");
                  window.scrollTo(0, 0);

                }}
              >
                DocTalk
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "fault-detection" ? "active" : ""
              }`}
            >
              <Link
                to="/fault-detection"
                onClick={() => {
                  toggleNav();
                  setLastClicked("fault-detection");
                  window.scrollTo(0, 0);

                }}
              >
                Fault-Detection
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "spanfi" ? "active" : ""
              }`}
            >
              <Link
                to="/spanfi"
                onClick={() => {
                  toggleNav();
                  setLastClicked("spanfi");
                  window.scrollTo(0, 0);

                }}
              >
                SpanFi
              </Link>
            </div>
            <div
              onClick={() => {
                toggleNav();
              }}
            >
              <TextCapsules
                text={"Contact us"}
                textColor={"white"}
                bgColor={"#2D2D2D"}
                isClickable={true}
                href="#contact-us"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Navbar;
