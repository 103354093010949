import React from "react";
import Hero from "./Sections/Hero";
import Featured from "./Sections/Featured";
// import FAQ from "./Sections/FAQ";
import ContactUs from "../../Common/ContactUs/ContactUs";

const Home = () => {
  return (
    <div className="">
      <Hero />
      <Featured />
      <ContactUs />
    </div>
  );
};

export default Home;
